import $ from './libs/jquery'
import { redirectTo, refreshSVG } from './addons/utils'

function initProductsAppFiltering() {
  const form = $('.js-products-filtering-form')
  const productsList = $('.js-products')
  const productSelect = $('.js-products-select-direct')

  form.find('input, select').on('change', () => {
    const query = form.serialize()
    const url = `${window.location.pathname}?${query}`

    productsList.addClass('loading')
    productsList.load(`${url} .js-products-app`, () => {
      productsList.removeClass('loading')
      refreshSVG(productsList)
      if ('pushState' in window.history) {
        window.history.pushState({}, '', url)
      }

      initProductsAppFiltering()
    })
  })
  productSelect.on('change', () => {
    const { url } = productSelect.find('option:selected').data()

    if (!url) {
      return
    }

    redirectTo(url)
  })
}

$(() => {
  initProductsAppFiltering()
})
